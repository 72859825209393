import {
  TextField,
  TextInput,
  TabbedShowLayout,
  ReferenceField,
} from "react-admin";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { TowerList } from "./tower/towers";
import BusinessIcon from "@mui/icons-material/Business";
import { FederationList } from "./federatios";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../ra-lb-tools/components/myReference";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";

export const SystemIcon = BusinessIcon;

export const SystemList = (props) => (
  <MyList {...props} resource="systems">
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="description" />
    <ReferenceField source="ownerId" reference="users" link="show">
      <TextField source="email" />
    </ReferenceField>
  </MyList>
);

export const SystemEdit = (props) => {
  return (
    <Edit {...props}>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="description" multiline />
    </Edit>
  );
};

export const SystemCreate = (props) => {
  return (
    <Create {...props}>
      <TextInput source="name" />
      <TextInput source="description" multiline />
    </Create>
  );
};

export const SystemShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="description" />
              <ReferenceField source="ownerId" reference="users" link="show">
                <TextField source="email" />
              </ReferenceField>
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Towers" path="towers">
          <TowerList filter={{ system_id: id }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Retired Towers" path="towers-retired">
          <TowerList filter={{ system_id: id, status_id: 4 }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Federations" path="federations">
          <FederationList filter={{ system_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const SystemInput = (props) => {
  return <MyReferenceInput source="systemId" reference="systems" {...props} />;
};

export const SystemField = (props) => {
  return <MyReferenceField source="systemId" reference="systems" {...props} />;
};
