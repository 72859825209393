import { Loading, useGetList, useGetOne } from "react-admin";
import { TowerRenderColumn } from "./TowerRenderColumn";

export const TowerRenderByTowerId = ({ towerId, ...rest }) => {
  if (!towerId) return null;

  const towerRequest = useGetOne("towers", { id: towerId });
  const lockerRequest = useGetList("lockers", {
    sort: { field: "id", order: "ASC" },
    filter: { towerId },
    pagination: { page: 1, perPage: 1000 },
  });

  if (towerRequest.isLoading || lockerRequest.isLoading) {
    return <Loading />;
  }
  if (towerRequest.error) {
    return <p>ERROR: could not request tower #{towerId}</p>;
  }

  if (lockerRequest.error) {
    return <p>ERROR: could not request lockers for tower #{towerId}</p>;
  }

  if (!towerRequest.data.towerModelId) {
    return (
      <p style={{ width: "200px" }}>
        Error: no tower model set for Tower {towerRequest.data.name} (#
        {towerRequest.data.id})
      </p>
    );
  }

  return (
    <TowerRenderWithData
      towerModelId={towerRequest.data.towerModelId}
      tower={towerRequest.data}
      lockers={lockerRequest.data}
      {...rest}
    />
  );
};

const TowerRenderWithData = ({ towerModelId, tower, lockers, ...rest }) => {
  const towerModelRequest = useGetOne("tower-models", {
    id: towerModelId,
  });

  if (towerModelRequest.isLoading) {
    return <Loading />;
  }
  if (towerModelRequest.error) {
    return <p>ERROR: could not request tower model #{towerModelId}</p>;
  }

  const specs = lockers.map((locker, index) =>
    Object.assign({}, towerModelRequest.data.lockersSpec[index], locker)
  );

  const header = [
    `Tower '${tower.name}' (#${tower.id})`,
    `Model '${towerModelRequest.data.name}' (#${towerModelRequest.data.id})`,
  ];

  return (
    <TowerRenderColumn
      {...{
        lockers: specs,
        numColumns: towerModelRequest.data.numColumns,
        header: header,
        ...rest,
      }}
    />
  );
};
