import React from "react";
import { BooleanField } from "react-admin";

export const MyBooleanField = (props) => (
  <BooleanField
    {...props}
    FalseIcon={React.forwardRef(function FalseIcon(props, ref) {
      return <div {...props} ref={ref}></div>;
    })}
    TrueIcon={React.forwardRef(function TrueIcon(props, ref) {
      return (
        <div {...props} ref={ref}>
          YES
        </div>
      );
    })}
  />
);
