import {
  TextField,
  NumberField,
  Edit,
  TextInput,
  useNotify,
  BooleanInput,
  ReferenceField,
} from "react-admin";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Button from "@mui/material/Button";
import LocationEditForm from "./locationEdit";
import { errorHandlingFactory } from "../../ra-lb-tools/utils/errors";
import NotesModalField from "../../components/notesModalField";
import { MyList } from "../../ra-lb-tools/components/myList";

export const LocationIcon = LocationOnIcon;

const LinkToRelatedTowers = ({ record }) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/towers",
        search: `filter=${JSON.stringify({ location_id: record.id })}`,
      }}
    >
      Towers
    </Button>
  ) : null;
};

const locationFilters = [
  <TextInput source="name" alwaysOn key={1} />,
  <TextInput source="search" alwaysOn key={2} />,
  <BooleanInput source="exclude_retired_or_empty" alwaysOn key={3} />,
  <TextInput source="city" key={"city"} />,
  <TextInput source="state" key={"state"} />,
  <TextInput source="country" key={"country"} />,
];

export const LocationList = (props) => (
  <MyList filters={locationFilters} resource="locations" {...props}>
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="address1" />
    <TextField source="address2" />
    <NumberField source="lat" />
    <NumberField source="lon" />
    <TextField source="city" />
    <TextField source="state" />
    <TextField source="zip" />
    <TextField source="country" />
    <NotesModalField source="notes" />
    <ReferenceField source="ownerId" reference="users" link="show">
      <TextField source="email" />
    </ReferenceField>
    <LinkToRelatedTowers />
  </MyList>
);

export const LocationEdit = () => {
  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  return (
    <Edit mutationOptions={{ onError }}>
      <LocationEditForm />
    </Edit>
  );
};
