export const statusChoices = [
  { id: 1, name: "Available" },
  { id: 2, name: "Out of service" },
  { id: 3, name: "Pending" },
  { id: 4, name: "Retired" },
];

export const accessModeChoices = [
  { id: 1, name: "Public" },
  { id: 2, name: "Restricted" },
];
