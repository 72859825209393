import {
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  NumberInput,
  ReferenceInput,
  DateTimeInput,
  BooleanInput,
  Show,
  SimpleShowLayout,
  FunctionField,
  useTheme,
  useRecordContext,
} from "react-admin";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { MyList } from "../ra-lb-tools/components/myList";
import CancelReservationButton from "../components/buttonCancelReservation";
import { Box } from "@mui/material";

export const ReservationIcon = EventSeatIcon;

const reservationFilters = [
  <NumberInput label="Locker Id" source="locker_id" alwaysOn key={1} />,
  <TextInput label="Tower id" source="locker__tower_id" alwaysOn key={2} />,
  <BooleanInput label="Canceled" source="canceled_at" key={3} />,
  <DateTimeInput label="Stared after" source="started_at_gt" key={4} />,
  <DateTimeInput label="Expires after" source="expires_at_gt" key={4} />,
  <ReferenceInput
    source="reservations##user_id"
    label="User"
    reference="users"
    allowEmpty
    key="reservations##user_id"
  >
    <AutocompleteInputFluid
      label="User"
      filterToQuery={(searchText) => ({ email: `%${searchText}` })}
      optionText="email"
      optionValue="id"
    ></AutocompleteInputFluid>
  </ReferenceInput>,
];

const is_inactive = (record) => record.statusData.id !== 1;

const rowStyle = (record, palette) => {
  return {
    backgroundColor: is_inactive(record)
      ? palette.specialColors.oos
      : palette.background.default,
  };
};

const ReservationStatus = (props) => {
  const record = useRecordContext();

  if (is_inactive(record)) {
    return (
      <Box sx={{ textTransform: "capitalize" }}>{record.statusData.name}</Box>
    );
  }

  return <CancelReservationButton {...props} />;
};

export const ReservationList = (props) => {
  const resource = "reservations";
  const palette = useTheme()[0].palette;

  return (
    <MyList
      {...props}
      filters={reservationFilters}
      resource={resource}
      dataGridProps={{
        rowStyle: (record) => rowStyle(record, palette),
      }}
    >
      <TextField source="id" />
      <ReferenceField
        source="lockerId"
        reference="lockers"
        link="show"
        label="Locker"
      >
        <FunctionField render={(record) => `${record.name} (#${record.id})`} />
      </ReferenceField>
      <DateField source="startedAt" showTime />
      <DateField source="expiresAt" showTime />
      <DateField source="canceledAt" showTime />
      <ReferenceField source="userId" reference="users" link="show">
        <TextField source="email" />
      </ReferenceField>

      <ReservationStatus />
    </MyList>
  );
};

export const ReservationShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField
          source="lockerId"
          reference="lockers"
          link="show"
          label="Locker"
        >
          <FunctionField
            render={(record) => `${record.name} (#${record.id})`}
          />
        </ReferenceField>
        <DateField source="startedAt" showTime={true} />
        <DateField source="expiresAt" showTime={true} />
        <DateField source="canceledAt" showTime={true} />
        <ReferenceField source="userId" reference="users" link="show">
          <TextField source="email" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
