import {
  TextField,
  TextInput,
  ReferenceInput,
  ReferenceField,
  Show,
  SimpleShowLayout,
  Labeled,
} from "react-admin";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImportRemoteLockersButton from "../components/buttonImportRemoteLockers";
import AutocompleteInputFluid from "../ra-lb-tools/components/autocompleteInputFluid";
import { LocationField, LocationInput } from "./locations/components";
import { MyList } from "../ra-lb-tools/components/myList";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { SystemField, SystemInput } from "./systems";

export const LocationIcon = LocationOnIcon;

export const FederationList = (props) => (
  <MyList {...props} resource="federations">
    <TextField source="id" />
    <TextField source="remoteId" />
    <SystemField label="System" />
    <LocationField label="Location" />
    <ReferenceField
      source="lockerProviderId"
      reference="locker-providers"
      link="show"
    >
      <TextField source="name" />
    </ReferenceField>
    <ImportRemoteLockersButton />
  </MyList>
);

export const FederationEdit = (props) => (
  <Edit {...props}>
    <TextInput disabled source="id" />
    <TextInput disabled source="remoteId" />
    <SystemInput disabled label="System" slack={10} />
    <LocationInput />
  </Edit>
);

export const FederationShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="remoteId" />
      <SystemField label="System" />
      <Labeled>
        <LocationField label="Location" />
      </Labeled>
      <ReferenceField
        source="lockerProviderId"
        reference="locker-providers"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const FederationCreate = (props) => (
  <Create {...props}>
    <TextInput source="remoteId" />
    <ReferenceInput source="ownerId" reference="users">
      <AutocompleteInputFluid
        filterToQuery={(searchText) => ({ email: `%${searchText}` })}
        optionText="email"
        optionValue="id"
      ></AutocompleteInputFluid>
    </ReferenceInput>
    <TextInput source="systemName" />
    <ReferenceInput source="lockerProviderId" reference="locker-providers">
      <AutocompleteInputFluid
        filterToQuery={(searchText) => ({ name: `%${searchText}` })}
        optionText="name"
        optionValue="id"
      ></AutocompleteInputFluid>
    </ReferenceInput>
  </Create>
);
