import {
  TextField,
  EmailField,
  TextInput,
  BooleanInput,
  PasswordInput,
  DateField,
  SelectInput,
  TabbedShowLayout,
  useTheme,
  useGetIdentity,
  useRecordContext,
  SelectField,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  validateUserCreation,
  validateUserUpdate,
} from "../utils/customValidators";
import { MyBooleanField as BooleanField } from "../components/myBooleanField";

import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { Box, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import { TowerList } from "./tower/towers";
import { ReservationList } from "./reservations";
import { openLockerTokenList as OpenLockerTokenList } from "./openLockerTokens";
import get from "lodash/get";
import { Settings } from "../ra-lb-tools/utils/settings";
import { ListHeader, MyList } from "../ra-lb-tools/components/myList";
import { SystemList } from "./systems";
import { LocationList } from "./locations/locations";
import {
  MyEdit as Edit,
  MyCreate as Create,
} from "../ra-lb-tools/components/myMutator";
import { LockerUsageList } from "./lockerUsage";
import { sxSpot } from "../ra-lb-tools/theme/styles";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../ra-lb-tools/components/myReference";
import { OrganizationList } from "./billing/organizations";
import { PersonOutline, WarningAmber } from "@mui/icons-material";
import { Row } from "../ra-lb-tools/components/row";
import { useState } from "react";
import { NullableBooleanField } from "../ra-lb-tools/components/nullableBooleanField";

export const UserIcon = PersonOutline;

const roles = {
  SUPERUSER: 1,
  SERVICE_PROVIDER: 2,
  TECHNICIAN: 3,
};

const roleChoices = [
  { id: roles.SERVICE_PROVIDER, name: "Service Provider" },
  { id: roles.SUPERUSER, name: "Superuser" },
  { id: roles.TECHNICIAN, name: "Technician" },
];

const rowStyle = (record, palette, currentUserId) => {
  return {
    backgroundColor:
      record.id == currentUserId && Settings.debugging()
        ? palette.specialColors.highlight
        : palette.background.default,
  };
};

const userFilters = [
  <TextInput label="Email" source="email" alwaysOn key={1} />,
  <NullableBooleanField source="enabled" label="Is active" key={2} alwaysOn />,
  <NullableBooleanField
    source="is_service_account"
    label="Is service account"
    key={3}
    alwaysOn
  />,
];

const TowersOwned = ({ source }) => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record[source]) return null;

  const retired = source.endsWith("Retired");

  const onClick = (e) => e.stopPropagation();
  const to = `/users/${record.id}/show/towers` + (retired ? "-retired" : "");

  return (
    <Box sx={{ display: "flex" }}>
      <Link
        to={to}
        onClick={onClick}
        style={sxSpot({ bg: retired ? "red" : "green" })}
      >
        <TextField source={source} />
      </Link>
      {get(record, "attributes.canOwnTowers") ? (
        ""
      ) : (
        <Box title={`User #${record.id} can not own towers`}>
          <WarningAmber sx={{ color: "red" }} />
        </Box>
      )}
    </Box>
  );
};

export const UserList = (props) => {
  const theme = useTheme();
  const palette = theme[0].palette;
  const currentUserId = get(useGetIdentity(), "data.id");

  return (
    <MyList
      {...props}
      resource="users"
      filters={userFilters}
      dataGridProps={{
        rowStyle: (record) => rowStyle(record, palette, currentUserId),
        omit: ["id"],
      }}
    >
      <TextField source="id" sortable={false} />
      <TextField source="fullName" sortable={false} />
      <EmailField source="email" sortable={false} />
      <BooleanField source="emailValidated" sortable={false} />
      <BooleanField source="isActive" sortable={false} />
      <SelectField
        source="role"
        choices={roleChoices}
        optionText="name"
        optionValue="id"
      />
      <BooleanField
        source="attributes.isServiceAccount"
        label="Is service account"
        sortable={false}
      />
      <BooleanField
        source="attributes.canOwnTowers"
        label="Can own towers"
        sortable={false}
      />
      <TowersOwned
        label="Towers Owned Active"
        source={"towersOwned"}
        sortable={false}
      />
      <TowersOwned
        label="Towers Owned Retired"
        source={"towersOwnedRetired"}
        sortable={false}
      />
      <TextField source="status" sortable={false} />
      <DateField source="createdAt" sortable={false} />
    </MyList>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const UserPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <ListHeader resource={"users"} />
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="Service Accounts" {...a11yProps(1)} />
          <Tab label="Inactive Users" {...a11yProps(2)} />
          <Tab label="All" {...a11yProps(3)} />
        </Tabs>
      </Box>
      {value === 0 && (
        <UserList
          noHeaders
          filter={{ isServiceAccount: false, enabled: true }}
        />
      )}
      {value === 1 && (
        <UserList noHeaders filter={{ isServiceAccount: true }} />
      )}
      {value === 2 && (
        <UserList
          noHeaders
          filter={{ isServiceAccount: false, enabled: false }}
        />
      )}
      {value === 3 && <UserList noHeaders />}
    </Box>
  );
};

export const UserEdit = (props) => {
  return (
    <Edit
      {...props}
      formProps={{
        mode: "onBlur",
        reValidateMode: "onBlur",
        validate: validateUserUpdate,
      }}
    >
      <TextInput source="id" disabled />
      <TextInput source="email" />
      <BooleanInput source="emailValidated" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <PasswordInput source="password" />
      <BooleanInput source="isActive" />
      <SelectInput
        source="role"
        choices={roleChoices}
        optionText="name"
        optionValue="id"
      />
      <BooleanInput
        source="attributes.isServiceAccount"
        label="Is service account"
      />
      <BooleanInput source="attributes.canOwnTowers" label="Can own towers" />
    </Edit>
  );
};

export const UserCreate = (props) => {
  return (
    <Create
      {...props}
      formProps={{
        mode: "onBlur",
        reValidateMode: "onBlur",
        validate: (values) => validateUserCreation(values, roles),
      }}
    >
      <TextInput source="email" />
      <BooleanInput source="emailValidated" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <PasswordInput source="password" />
      <BooleanInput source="isActive" />
      <SelectInput
        source="role"
        choices={roleChoices}
        optionText="name"
        optionValue="id"
      />
      <BooleanInput
        source="attributes.isServiceAccount"
        label="Is service account"
      />
      <BooleanInput source="attributes.canOwnTowers" label="Can own towers" />
    </Create>
  );
};

export const UserShow = () => {
  const id = useParams()["id"];

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Row sx={{ justifyContent: "start" }}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="fullName" />
              <EmailField source="email" />
              <BooleanField source="emailValidated" />
              <TextField source="status" />
              <DateField source="createdAt" />
            </LabeledColumn>
            <LabeledColumn>
              <BooleanField source="isActive" />
              <BooleanField
                source="attributes.isServiceAccount"
                label="Is service account"
              />
              <BooleanField
                source="attributes.canOwnTowers"
                label="Can own towers"
              />
              <SelectField
                source="role"
                choices={roleChoices}
                optionText="name"
                optionValue="id"
              />
            </LabeledColumn>
          </Row>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Towers" path="towers">
          <TowerList filter={{ owner_id: id }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Retired Towers" path="towers-retired">
          <TowerList filter={{ owner_id: id, status_id: 4 }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Reservations" path="reservations">
          <ReservationList filter={{ user_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Open Locker Token"
          path="open-locker-tokens"
        >
          <OpenLockerTokenList filter={{ user_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Systems" path="systems">
          <SystemList filter={{ owner_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Locations" path="locations">
          <LocationList filter={{ owner_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Locker Usage" path="locker-usage">
          <LockerUsageList filter={{ user_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Owned Organizations"
          path="owned-organizations"
        >
          <OrganizationList filter={{ account_owner_id: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Serviced Organizations"
          path="serviced-organizations"
        >
          <OrganizationList filter={{ service_account_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};

export const UserInput = (props) => {
  return (
    <MyReferenceInput reference="users" primaryField={"email"} {...props} />
  );
};

export const UserField = (props) => {
  return (
    <MyReferenceField reference="users" primaryField={"email"} {...props} />
  );
};
