import { cloneElement } from "react";
import { Settings } from "./settings";
import { Debug } from "../components/debug";
import { Store } from "./store";

export const ENV_TYPES = {
  PROD: {
    favicon: "favicon.ico",
    grafana: {
      namespace: "hal-backend",
    },
  },
  LOCAL: {
    label: "local env",
    barSx: { backgroundColor: "lightgreen" },
    favicon: "favicon_local.ico",
    grafana: {
      namespace: "local-backend",
    },
  },
  DEV: {
    label: "dev env",
    barSx: { backgroundColor: "darkorchid" },
    favicon: "favicon_dev.ico",
    grafana: {
      namespace: "sal-backend",
    },
  },
  SANDBOX: {
    label: "sandbox",
    barSx: { backgroundColor: "darkgoldenrod" },
    favicon: "favicon_snd.ico",
    grafana: {
      namespace: "sandbox-backend",
    },
  },
};

export const getEnvStyling = () => {
  const host = window.location.hostname.toLowerCase().split(".");

  if (host[host.length - 1] == "localhost") {
    return ENV_TYPES.LOCAL;
  } else if (host.includes("dev")) {
    return ENV_TYPES.DEV;
  } else if (host.includes("sandbox")) {
    return ENV_TYPES.SANDBOX;
  } else {
    return ENV_TYPES.PROD;
  }
};

export const ENV = () => {
  return Object.assign({}, window.ENV, Store.get("ENV"));
};

export const setFavicon = (favicon) => {
  var link = document.querySelector("link[rel~='icon']");
  if (link) {
    const href = link.href.split("/");
    href.pop();
    href.push(favicon);
    link.href = href.join("/");
  }
};

export const setEnvFavicon = () => {
  const env_styling = getEnvStyling();

  if (env_styling.favicon) {
    setFavicon(env_styling.favicon);
  }
};

const asArray = (value) => (Array.isArray(value) ? value : [value]);

const addKeys = (comps) =>
  comps.map((comp, index) => cloneElement(comp, { key: index }));

export const getFields = (userFields, debugFields) => {
  let fields = [].concat(asArray(userFields.props.children));

  if (Settings.debugging()) {
    fields = fields.concat(
      asArray(debugFields.props.children).map((debugField, index) => (
        <Debug key={index} source={debugField.props.source}>
          {debugField}
        </Debug>
      ))
    );
  }

  fields = addKeys(fields);

  return fields;
};

export const objectMap = ({
  obj = {},
  valueCallback = (_value, _key) => _value,
  keyCallback = (_key, _value) => _key,
}) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      keyCallback(key, value),
      valueCallback(value, key),
    ])
  );

export const toClipboard = (data) => {
  if (typeof data === "object") {
    data = JSON.stringify(data);
  }

  navigator.clipboard.writeText(data);
};

export const splitArray = (arr, columns) => {
  const chunkSize = Math.ceil(arr.length / columns);

  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

function capitalizeFirstChar(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Returns variations of the string on different casings
 * @param {*} str
 */
export const expandCasing = (str) => {
  let comps = str.split(/(?=[A-Z])|[^a-zA-Z0-9]+/).filter(Boolean);

  const casings = {
    str,
  };

  casings.snake = comps.map((s) => s.toLowerCase()).join("_");
  casings.camel = comps
    .map((s) => s.toLowerCase())
    .map((s, idx) => (idx ? capitalizeFirstChar(s) : s))
    .join("");

  return casings;
};

export const expandCasings = (arr) => {
  let casings = [];

  arr.forEach(
    (str) => (casings = casings.concat(Object.values(expandCasing(str))))
  );

  return casings;
};
