import get from "lodash/get";

export const getErrorMessage = (error) => {
  let detail = get(error, "body.detail") || get(error, "detail") || "";

  if (Array.isArray(detail)) {
    return detail
      .map((err) => {
        let source = get(err, "loc[1]", "");
        source = source ? source + ": " : source;

        const msg = err.msg || get(err, "ctx.message");

        return `${source}${msg}`;
      })
      .join(". ");
  }

  return `${error.message} -> ${detail}`;
};

export const errorDisplayConfig = {
  autoHideDuration: 10000,
  multiLine: true,
  type: "error",
};

export const errorHandlingFactory = (notifier) => {
  const onError = (error) => {
    notifier(getErrorMessage(error), errorDisplayConfig);
  };
  return onError;
};
