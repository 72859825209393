import { Column } from "../../ra-lb-tools/components/column";
import { TowerRender } from "./TowerRender";

export const TowerRenderColumn = ({
  columnProps,
  containerWidth,
  renderAlwaysVisible,
  setRenderAlwaysVisible,
  setHighlightList,
  highlight_key,

  ...rest
}) => {
  containerWidth = containerWidth || window.innerWidth * 0.25;

  const scrollBarWidth = 20;
  const gutters = scrollBarWidth;

  const canvasWidth = containerWidth - gutters;
  const canvasSize = {
    width: canvasWidth,
  };

  const sx = renderAlwaysVisible
    ? {
        paddingTop: "20px",
      }
    : {};

  const onMouseEnter = (locker) =>
    setHighlightList && setHighlightList([locker[highlight_key]]);

  const onMouseLeave = () => setHighlightList && setHighlightList([]);
  const onAlwaysVisibleChange = setRenderAlwaysVisible
    ? (value) => setRenderAlwaysVisible(value)
    : null;

  const { className, sx: _sx, ..._columnProps } = columnProps;

  return (
    <Column
      className={"TowerRenderColumn " + className}
      minWidth={containerWidth}
      sx={{ ...sx, ..._sx }}
      {..._columnProps}
    >
      <TowerRender
        canvasSize={canvasSize}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        alwaysVisible={renderAlwaysVisible}
        onAlwaysVisibleChange={onAlwaysVisibleChange}
        {...rest}
      />
    </Column>
  );
};

TowerRenderColumn.defaultProps = {
  highlightList: [],
  highlight_key: "physicalId",
  columnProps: {},
};
